div#wave {
  position: relative;
  text-align: center;
  .dot {
    display: inline-block;
    margin-right: 2px;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: #303131;
    animation: wave 1.3s linear infinite;

    &:nth-child(2) {
      animation-delay: -1.1s;
    }

    &:nth-child(3) {
      animation-delay: -0.9s;
    }
  }
}

@keyframes wave {
  0%,
  60%,
  100% {
    transform: initial;
  }

  30% {
    transform: translateY(-5px);
  }
}
